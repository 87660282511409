import { useState } from "react";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "../AboutUsFaq/AboutUsFaq.module.css";
import { activeToggler } from "v2/HelperFunctions/activeTogglers";

const TutionFaq = () => {
  const faqs = [
    {
      question: "What payment model does iṣẹ́ School use?",
      answer:
        "We use a pay-per-course model. Once you pay for a course, you have access to the entire course.",
    },
    {
      question: "What payment methods are acceptable for tuition fees?",
      answer:
        "We accept mobile transfers and card transactions with Verve, Visa or Master cards.",
    },
    {
      question:
        "Is there a refund policy for tuition fees if I need to cancel or withdraw from a course?",
      answer:
        "We have refund policies, but they are based on certain criteria. Read about our refund policy (link to policy page)",
    },
    {
      question:
        "Are there additional fees or hidden costs besides the course tuition?",
      answer:
        "There are no additional fees or hidden costs. What you see at checkout is what you pay for.",
    },
    {
      question:
        "What happens if I miss a tuition payment deadline? Are there late fees?",
      answer:
        "If you miss a deadline, your access to the course will be paused until payment is complete. There are no late fees. To help you meet up with payment, we've made provision for you to split payment into 3 smaller instalments from your dashboard.",
    },
  ];

  const [faqState, setFaqState] = useState(
    faqs.map((data) => {
      return { ...data, isActive: false };
    })
  );

  return (
    <>
      {faqState.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown
              question={data?.question}
              answer={data?.answer}
              onClick={() => {
                activeToggler(i, faqState, setFaqState);
              }}
              isActive={data?.isActive}
            />
          </div>
        );
      })}
    </>
  );
};

export default TutionFaq;
