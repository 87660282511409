import { useEffect } from "react";

/**
 * A custom React hook that runs a callback function at a specified interval.
 *
 * @param {Function} callback - The function to be executed at each interval.
 * @param {number|null} delay - The delay in milliseconds for the interval. If null, the interval is stopped.
 */
const useInterval = (callback: () => void, delay: number) => {
  useEffect(() => {
    const intervalId = setInterval(callback, delay);
    return () => clearInterval(intervalId);
  }, [callback, delay]);
};

export default useInterval;
