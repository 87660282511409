import { activeToggler } from "v2/HelperFunctions/activeTogglers";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "../AboutUsFaq/AboutUsFaq.module.css";
import { useState } from "react";

const CoursesFaq = () => {
  const faqs = [
    {
      question: "Are the courses self-paced, or do they have fixed schedules?",
      answer:
        "The courses are self-paced. But we also make provision for live classes once a week, these classes are recorded for those who can't attend live.",
    },
    {
      question: "Can I track my progress and performance in a course?",
      answer:
        "Yes. Your dashboard provides visual progress tracking and insights into your learning achievements.",
    },
    {
      question: "Is there a certification upon course completion?",
      answer:
        "Yes. We will award you an industry-recognised certificate upon completion of the course.",
    },
    {
      question: "Can I access course materials after I complete the course?",
      answer:
        "Yes. You can always have access to your paid courses on your dashboard.",
    },
    {
      question: "Are there assessments or quizzes in the courses?",
      answer:
        "Yes. Assessments at the end of each module test your understanding of the topic. Not to worry, you can retake the examination.",
    },
    {
      question: "Can I switch to a different course after enrolment?",
      answer:
        "No. You can't switch courses after enrolment. Make sure you choose a course carefully before you make payment.",
    },
  ];

  const [faqState, setFaqState] = useState(
    faqs.map((data) => {
      return { ...data, isActive: false };
    })
  );

  return (
    <>
      {faqState.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown
              question={data?.question}
              answer={data?.answer}
              onClick={() => {
                activeToggler(i, faqState, setFaqState);
              }}
              isActive={data?.isActive}
            />
          </div>
        );
      })}
    </>
  );
};

export default CoursesFaq;
