import Layout from "v2/Components/Layout/Layout";

import IMAGE_SPONSOR_HERO from "Assets/Images/sponsor-hero.jpg";
import IMAGE_SPONSOR_GROUP from "Assets/Images/sponsor-group.jpg";
import IMAGE_DONATE_A_LAPTOP from "Assets/Images/donate-a-laptop.jpg";
import Button from "v2/Components/Button/Button";
import FAQDropdown from "v2/Components/FAQDropdown/FAQDropdown";
import { activeToggler } from "v2/HelperFunctions/activeTogglers";
import { useState } from "react";

const SponsorAStudent = () => {
  const faqs = [
    {
      question: "1. Will I receive updates on the student's progress?",
      answer:
        "Yes, we will provide periodic updates on the student's progress throughout their course of study.",
    },
    {
      question: "2. How much does it cost to sponsor a student?",
      answer:
        "The cost of sponsoring a student depends on the course they are enrolled in. The price of the courses starts at 40,000 Naira. Please visit our course page to see the cost for each course",
    },
    {
      question: "3. Can I choose which student I want to sponsor?",
      answer:
        "No, you can't choose a student. We have a system that selects who gets sponsored to prevent bias.",
    },
    {
      question: "4. Can I donate a laptop instead of sponsoring a student??",
      answer: `Yes, you can donate a laptop. Click on the button "Donate now" to fill the donation form. The form requires basic information about the laptop you’d be donating`,
    },
    {
      question: "5. How do I know my donation is being used appropriately?",
      answer: `You can trust our commitment to transparency. We'll provide a personalized downloadable report showing exactly how we utilized your donation if you request it.`,
    },
  ];

  const [faqState, setFaqState] = useState(
    faqs.map((data) => {
      return { ...data, isActive: false };
    })
  );

  return (
    <Layout>
      <section className="pt-[150px] pb-14 md:pb-0 px-5 md:px-[80px] bg-[#FFFAEB] min-h-[650px] flex justify-between">
        <div className="max-w-[628px] md:pt-20">
          <p className=" text-[10px] md:text-[13px] font-medium md:tracking-[5%] text-[#1B695C] mb-2.5">
            WE NEED YOU
          </p>

          <h1 className="text-[36px] md:text-[61px] leading-[45px] md:leading-[75px] font-medium">
            Sponsor a student in tech
          </h1>

          <div className="md:hidden mt-5">
            <img
              src={IMAGE_SPONSOR_HERO}
              className="min-w-[343px] object-cover rounded-[10px]"
              alt="sponsor-hero"
            />
          </div>

          <p className="text-[#2E2E2E] mt-5">
            Make a difference in a student's tech journey by offering
            scholarships, laptops, and valuable opportunities. Empower the next
            generation of tech leaders with your support.
          </p>
        </div>

        <div className="hidden md:block">
          <img
            src={IMAGE_SPONSOR_HERO}
            className="max-w-[678px] object-cover rounded-[10px]"
            alt="sponsor-hero"
          />
        </div>
      </section>

      <section className="px-5 md:px-[80px] min-h-[500px] pb-14 flex justify-between">
        <div className="hidden md:flex items-center">
          <img
            src={IMAGE_SPONSOR_GROUP}
            className="w-[442px] object-cover"
            alt="sponsor-hero"
          />
        </div>

        <div className="max-w-[524px] pt-10 md:pt-20">
          <h1 className="text-[31px] md:text-[49px] leading-[75px] font-medium">
            Sponsor student fees
          </h1>

          <div className="md:hidden flex items-center mb-8">
            <img
              src={IMAGE_SPONSOR_GROUP}
              className="w-[334px] object-cover"
              alt="sponsor-hero"
            />
          </div>

          <p className="text-[#2E2E2E] mt-2">
            Empower tech minds with your sponsorship. Cover the fees of as many
            students' as possible and open doors to endless possibilities in the
            tech world. Make a difference in their tech journey today
          </p>

          <div className="w-[208px] mt-10">
            <Button>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScSMnwjUR_bdkK1nl_RmvXuuh60GHxE3n4sCRX79ZyffhPtLw/viewform?usp=pp_url"
                target="_blank"
                rel="noreferrer"
              >
                Sponsor a student
              </a>
            </Button>
          </div>
        </div>
      </section>

      <section className="px-5 md:px-[80px] min-h-[530px] bg-[#F7F5FF] flex items-center justify-between pb-12 md:pb-0">
        <div className="max-w-[524px]">
          <h1 className="text-[31px] md:text-[49px] leading-[75px] font-medium">
            Donate a laptop
          </h1>

          <div className="md:hidden flex items-center mb-6">
            <img
              src={IMAGE_DONATE_A_LAPTOP}
              className="w-full object-cover"
              alt="sponsor-hero"
            />
          </div>

          <p className="text-[#2E2E2E] mt-2">
            Support tech lovers with a laptop donation. Your contribution
            provides essential tools for their educational journey, empowering
            them to thrive in the digital world and create a brighter future.
          </p>

          <div className="w-[158px] mt-10">
            <Button>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSd5L7GoEudSvoxNl86KoApKzzOl99TCkVFIuVHyQNKkLgctjA/viewform?usp=pp_url"
                target="_blank"
                rel="noreferrer"
              >
                Donate now
              </a>
            </Button>
          </div>
        </div>

        <div className="hidden md:flex items-center">
          <img
            src={IMAGE_DONATE_A_LAPTOP}
            className="w-[668px] object-cover"
            alt="sponsor-hero"
          />
        </div>
      </section>

      <section className="py-20 px-5">
        <p className="text-center text-[10px] md:text-[13px] text-[#1B695C] tracking-[5%] font-medium">
          YOU HAVE QUESTIONS?
        </p>

        <h3 className="text-center text-[#2E2E2E] text-[36px] md:text-[49px] font-medium">
          We’ve got answers
        </h3>

        <div className="flex flex-col gap-y-4 max-w-[700px] mx-auto mt-10">
          {faqState.map((data, i) => {
            return (
              <FAQDropdown
                question={data?.question}
                answer={data?.answer}
                onClick={() => {
                  activeToggler(i, faqState, setFaqState);
                }}
                isActive={data?.isActive}
              />
            );
          })}
        </div>
      </section>
    </Layout>
  );
};

export default SponsorAStudent;
