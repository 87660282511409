import Button from "../../Components/Button/Button";
import classes from "./HomePageHeroSection.module.css";
// import heroSectionImage from "../../Assets/Images/heroSection.svg";
import avantLogo from "../../Assets/Images/avantLogo.svg";
import ishaLogo from "../../Assets/Images/ishaLogo.svg";
import chargeBee from "../../Assets/Images/chargeBee.svg";
import clane from "../../Assets/Images/clane.svg";
import paga from "../../Assets/Images/paga.svg";
import robinHood from "../../Assets/Images/robinHood.svg";
import sofiLogo from "../../Assets/Images/sofiLogo.svg";
import stripe from "../../Assets/Images/stripe.svg";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import Svgs from "../../../Assets/Svgs";

const brands = [
  avantLogo,
  sofiLogo,
  ishaLogo,
  stripe,
  clane,
  robinHood,
  chargeBee,
  paga,
];

const HomePageHeroSection = () => {
  // Context
  const { scrollTOView, coursesRef } = useContext(AppContext);
  return (
    <section className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.textSection}>
          <div className={classes.topSection}>
            <Svgs.Active />

            <span>WE’RE LOOKING FOR YOU 👋</span>
          </div>
          <h1>
            Build your tech <br />
            skills in{" "}
            <span className="relative">
              <Svgs.Eclipse658 className="absolute top-0 left-0 text-[#664EFE]" />
              3 months{" "}
            </span>
          </h1>
          <p>
            Become a tech superstar with expert guidance, personalised learning,
            and a strong tech community. No more playing small. Are you ready to
            put in the work?
            <svg
              width="87"
              height="73"
              viewBox="0 0 87 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M86.8404 1.47244C87.351 2.70914 86.3848 6.27493 83.2402 14.872C76.8237 32.4651 68.764 43.0913 54.4683 52.8839C46.6114 58.2636 37.7272 62.4797 30.9777 64.0293C24.8579 65.4197 18.1008 65.6258 12.8049 64.6102C11.4249 64.3553 10.262 64.1775 10.2551 64.2505C10.2165 64.3366 11.8398 65.7982 13.9036 67.5297C16.7394 69.9075 17.6485 70.8743 17.7246 71.4965C17.8703 72.4671 17.1753 72.8944 15.8972 72.6282C15.1369 72.4596 3.99913 63.9076 1.65777 61.6722C0.341353 60.4261 -0.0455071 59.3094 0.45228 58.3143C0.855938 57.5292 0.919322 57.503 2.53555 57.4543C9.5931 57.1899 14.151 56.2381 18.2632 54.1281L20.4475 52.9851L20.6928 53.8488C20.8971 54.5233 20.6895 54.9862 19.8062 55.9343C18.9158 56.9554 18.1891 57.3958 15.8563 58.3589C14.3038 58.9998 12.2196 59.6892 11.2586 59.9105C10.2829 60.1071 9.4502 60.3807 9.41555 60.4652C9.25112 60.7743 14.9758 61.6831 18.0547 61.82C21.6961 62.0054 25.4083 61.7449 29.1845 61.0105C33.1235 60.244 43.7894 55.8407 47.9933 53.2454C56.8506 47.7289 64.9406 40.5335 69.796 33.7786C73.9279 28.011 76.6029 22.4985 81.0299 10.7534C84.1145 2.62853 84.7474 1.33207 85.8643 0.87097C86.3317 0.678028 86.5712 0.820362 86.8389 1.46869L86.8404 1.47244Z"
                fill="#664EFE"
              />
            </svg>
          </p>

          <Button
            onClick={() => {
              scrollTOView(coursesRef);
            }}
          >
            Explore virtual schools
          </Button>
        </div>

        <div className={classes.imagesSection}>
          <img
            src="https://res.cloudinary.com/iseschool/image/upload/v1728917022/hero_image_svwqjv.png"
            alt="Welcome to Ise school"
          />
        </div>
      </div>

      <div className={classes.brandSection}>
        <h4>Where some of our tutors work</h4>
        <div className={classes.logoSection}>
          {brands.map((data) => (
            <img key={data} src={data} alt="Our tutor brands" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomePageHeroSection;
