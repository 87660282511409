import classes from "./HomePageLearn.module.css";
import personalized from "../../Assets/Images/personalized.svg";
import practical from "../../Assets/Images/practical.svg";
import oneToOne from "../../Assets/Images/oneToOne.svg";
import comprehensive from "../../Assets/Images/comprehensive.svg";
import { activeToggler } from "../../HelperFunctions/activeTogglers";
import { useState } from "react";
import Svgs from "../../../Assets/Svgs";

const learningMethods = [
  {
    title: "Personalised dashboard",
    description:
      "Stay organised, access course materials, track your learning progress, and engage with your peers all in one place.",
    image: personalized,
    isActive: true,
  },
  {
    title: "Practical assessment",
    description:
      "Gain practical experience and strengthen your skills with our interactive assessments and challenges.",
    image: practical,
    isActive: false,
  },
  {
    title: "1-on-1 sessions",
    description:
      "Benefit from personalized feedback and mentorship through exclusive and dedicated support from our expert tutors.",
    image: oneToOne,
    isActive: false,
  },
  {
    title: "Comprehensive course curriculum",
    description:
      "Build a solid learning foundation with a defined and detailed learning path. Confidently progress towards mastery with structured learning.",
    image: comprehensive,
    isActive: false,
  },
];

const HomePageLearn = () => {
  // State
  const [learningMethodsState, setLearningMethodsState] =
    useState(learningMethods);

  // Utils
  const activeMethod = learningMethodsState?.find((data) => data.isActive);

  return (
    <section className={classes.container}>
      <p className="font-medium text-[#1B695C] text-center text-[13px]">
        LEARN ON THE GO
      </p>

      <div className="flex items-center justify-center">
        <h2 className="font-medium text-[31px] md:text-[49px] text-[#2E2E2E]">
          Easy learning from{" "}
          <span className="relative">
            any device
            <Svgs.Eclipse658 className="absolute top-0 left-0 text-[#1B695C] w-[151.79px] h-[33.58px] md:w-[233px] md:h-[51px]" />
          </span>{" "}
        </h2>

        <span className="hidden md:flex">
          <svg
            width="71"
            height="18"
            viewBox="0 0 71 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.523 1.12501C55.9428 1.80935 56.428 2.81926 57.5196 3.21126C58.0343 3.37404 59.3194 3.6365 60.3816 3.76606C61.4438 3.89562 62.3125 4.05841 62.3125 4.19129C62.3125 4.55007 55.1724 8.72252 52.0186 10.2208C46.5504 12.8286 42.8524 13.2837 39.9249 11.7855C39.2496 11.4267 36.9974 9.6328 34.9714 7.809C32.9454 5.9852 30.4375 4.0285 29.3425 3.47372C27.5099 2.49704 27.2182 2.43059 23.8743 2.43059C20.5304 2.43059 20.1108 2.52693 17.7963 3.47372C13.3903 5.26762 4.06022 11.4599 0.942545 14.6225C-0.922813 16.5459 0.172113 17.9811 2.55217 16.7419C3.13243 16.4496 5.80096 14.7521 8.44 12.9947C16.9013 7.42031 20.4059 5.82239 24.0415 5.79249C26.9362 5.75927 28.8016 6.76919 33.306 10.7457C37.2301 14.2338 38.7087 15.1141 41.4133 15.6357C43.472 16.0277 45.2719 15.8317 48.1666 14.9181C51.1597 13.9415 53.3791 12.7655 58.9096 9.14775C65.2466 5.00848 64.827 5.23773 64.1516 6.18119C62.3519 8.78899 61.6732 10.2872 61.7388 11.5596C61.8338 12.6359 61.965 12.895 62.5748 12.9614C63.5091 13.091 64.1189 12.6027 64.8893 11.0712C65.2105 10.4201 66.7218 8.36708 68.2331 6.50674C70.4853 3.76938 71 2.95549 71 2.07515C71 1.45725 70.9049 0.96557 70.7738 0.935671C70.6132 0.935671 67.4594 0.869226 63.6959 0.806107C58.2277 0.709768 56.8115 0.77288 56.523 1.13166V1.12501Z"
              fill="#1B695C"
            />
          </svg>
        </span>
      </div>

      <div className={classes.informationSection}>
        <div className={classes.textContainer}>
          {learningMethodsState.map((data, i) => {
            return (
              <div
                className={` ${
                  data?.isActive ? classes.info : classes.inActive
                }`}
                key={data.title}
                onClick={() => {
                  activeToggler(
                    i,
                    learningMethodsState,
                    setLearningMethodsState
                  );
                }}
              >
                <h4>{data?.title}</h4>
                <p
                  style={
                    data?.isActive
                      ? { maxHeight: "300px" }
                      : { maxHeight: "0px" }
                  }
                >
                  {data.description}
                </p>
              </div>
            );
          })}
        </div>
        <div className={classes.imageSection}>
          <img
            src={activeMethod?.image as string}
            alt={activeMethod?.title as string}
          />
        </div>
      </div>
    </section>
  );
};

export default HomePageLearn;
