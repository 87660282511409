import Svgs from "../../../../Assets/Svgs";

import IMAGE_TEACH_TALENTS from "../../../Assets/Images/teach-talents.jpg";
import IMAGE_PARTNER_WITH_US from "../../../Assets/Images/partner-with-us.jpg";
import { Link } from "react-router-dom";

const CollaborateCard = (props: {
  altText: string;
  imageUrl: string;
  ctaText: string;
  title: string;
  paragraph: string;
  href: string;
}) => {
  return (
    <div className="border border-[#2E2E2E] rounded-[10px] flex w-[660px] h-[320px]">
      <div className="h-full w-1/2 bg-gray-100 shrink-0 rounded-s-[10px]">
        <img
          src={props.imageUrl}
          className="h-full w-full object-cover rounded-s-[10px]"
          alt={props.altText}
        />
      </div>
      <div className="px-5 flex flex-col my-auto">
        <h3 className="font-medium text-[25px] mb-2">{props.title}</h3>

        <p className="leading-[25px]">{props.paragraph}</p>

        <Link
          to={props.href}
          className="text-[#664EFE] text-lg font-medium mt-8 flex items-center"
        >
          {props.ctaText}
          <span className="ml-2">
            <Svgs.ArrowRight />
          </span>
        </Link>
      </div>
    </div>
  );
};

const CollaborateWithUs = () => {
  return (
    <section className="pb-24">
      <div className="w-[90%] mx-auto">
        <p className="text-[13px] text-[#664EFE] text-center">
          LET'S MAKE DREAMS COME TRUE
        </p>

        <h1 className="text-[49px] font-medium text-center mb-10">
          <span className="relative">
            Collaborate
            <Svgs.Eclipse658 className="absolute top-0 left-0 text-[#664EFE] w-[151.79px] h-[33.58px] md:w-[233px] md:h-[51px]" />
          </span>{" "}
          with us
        </h1>

        <div className="flex flex-col lg:flex-row items-center justify-center gap-8">
          <CollaborateCard
            title="Teach talents"
            paragraph="Share your knowledge and inspire the next generation of skilled African talents in the tech space."
            imageUrl={IMAGE_TEACH_TALENTS}
            altText="teach-talent"
            ctaText="Become a tutor"
            href="/become-a-tutor"
          />

          <CollaborateCard
            title="Partner with us"
            paragraph="Are you an individual or company passionate about education and building tech dreams?"
            imageUrl={IMAGE_PARTNER_WITH_US}
            altText="partner-with-us"
            ctaText="Partner with us"
            href="/sponsor-a-student"
          />
        </div>
      </div>
    </section>
  );
};

export default CollaborateWithUs;
