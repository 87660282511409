import axios from "axios";

export type TSchool = {
  id: number;
  name: string;
  description: string;
  image: string;
  position: number;
  tagline: string;
  tagline_title: null;
  facts: null;
  salary_range: null;
  salary_range_text: null;
  career_path_no: null;
  facts_cover_image: null;
  slug: string;
  status: string;
  courseCount: number;
};

const baseURL = process.env.REACT_APP_API_URL + "/api/ise/v1";

const axiosInstance = axios.create({ baseURL });

export const endpoints = {
  GET_SCHOOL_BY_SLUG: (schoolSlug: string) => "/school/" + schoolSlug,
  GET_ACTIVE_SCHOOLS: "/school/admin/get-active-schools",
  GET_SCHOOL_COURSES_BY_SLUG: (schoolSlug: string) =>
    "/school/" + schoolSlug + "/courses",
  GET_COURSE_BY_SLUG: (courseSlug: string) => "/courses/" + courseSlug,
};

export const getSchoolBySlug = (url: string) => {
  return axiosInstance.get<TSchool>(url);
};

export const getActiveSchools = (url: string) => {
  return axiosInstance.get<TSchool[]>(url);
};

export type TSchoolCourse = {
  courses_id: number;
  courses_name: string;
  courses_cover_image: string;
  courses_description: string;
  courses_slug: string;
  cohortcount: string;
};

export const getSchoolCoursesBySlug = (url: string) => {
  return axiosInstance.get<TSchoolCourse[]>(url);
};

export const getCoursesBySlug = (url: string) => {
  return axiosInstance.get<TCourse[]>(url);
};

type TCohort = {
  id: string;
  application_deadline: string;
  end_date: string;
  start_date: string;
  status: string;
  duration: string;
  price: number;
};

export type TCourse = {
  id: number;
  name: string;
  cover_image: string;
  description: string;
  slug: string;
  course_objective: string;
  publish: boolean;
  cohorts: TCohort[];
};

export const getCourseBySlug = (url: string) => {
  return axiosInstance.get<TCourse>(url);
};
