import classes from "../AboutUsFaq/AboutUsFaq.module.css";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import { activeToggler } from "v2/HelperFunctions/activeTogglers";
import { useState } from "react";

const faqs = [
  {
    question:
      "Are the courses tailored for beginners or suitable for experienced professionals too?",
    answer:
      "Our courses are for beginners trying to gain both technical and non-technical to start building their tech careers.",
  },
  {
    question: "What is the typical duration of the courses?",
    answer: "Our courses last for three months.",
  },
  {
    question: "Are the courses offered in-person or through virtual learning?",
    answer: "We offer virtual learning.",
  },
  {
    question:
      "Does iṣẹ́ School offer job placement assistance for its school graduates?",
    answer:
      "No, we don't. The capstone projects help you gain real-time experience and create a portfolio that gives you an advantage in getting entry-level roles.",
  },
  {
    question: "Why don't you have a School of Design?",
    answer:
      "We prioritize offering specialized tech courses to empower learners with unique skills that stand out in the competitive market. While we don't have a School of Design, our diverse range of tech-focused programs ensures exciting career opportunities and keeps you ahead in the ever-evolving tech landscape. Explore our offerings and embark on a limitless tech adventure with us.",
  },
];

const SchoolsFaq = () => {
  const [faqState, setFaqState] = useState(
    faqs.map((data) => {
      return { ...data, isActive: false };
    })
  );

  return (
    <>
      {faqState.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown
              question={data?.question}
              answer={data?.answer}
              onClick={() => {
                activeToggler(i, faqState, setFaqState);
              }}
              isActive={data?.isActive}
            />
          </div>
        );
      })}
    </>
  );
};

export default SchoolsFaq;
