import { useMemo } from "react";
import useSWR from "swr";

import Layout from "../../Components/Layout/Layout";
import { useSchool } from "../../Hooks/useSchools";
import { schoolsType } from "../../Utilities/types";
import HomePageAffordable from "../HomePageAffordable/HomePageAffordable";
import HomePageBuildWithEveryone from "../HomePageBuildWithEveryone/HomePageBuildWithEveryone";
import HomePageFaqs from "../HomePageFaqs/HomePageFaqs";
import HomePageHeroSection from "../HomePageHeroSection/HomePageHeroSection";
import HomePageLearn from "../HomePageLearn/HomePageLearn";
import HomePageSchools from "../HomePageSchools/HomePageSchools";
import HomePageWhoWeAre from "../HomePageWhoWeAre/HomePageWhoWeAre";
import CollaborateWithUs from "./Components/CollaborateWithUs";
import { endpoints, getActiveSchools } from "Services";

const Home = () => {
  // Requests
  // const { isLoading, data } = useSchool();

  // const schools: schoolsType[] = useMemo(() => data?.data, [data]);

  const { data: response, isLoading } = useSWR(
    endpoints.GET_ACTIVE_SCHOOLS,
    getActiveSchools
  );

  console.log("------>>", response);
  const activeSchools = response?.data;

  return (
    <Layout>
      <HomePageHeroSection />
      <HomePageSchools schools={activeSchools || []} isLoading={isLoading} />
      <HomePageLearn />
      <HomePageBuildWithEveryone />
      <HomePageWhoWeAre />
      <HomePageFaqs />
      <CollaborateWithUs />
      <HomePageAffordable />
    </Layout>
  );
};

export default Home;
