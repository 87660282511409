import Layout from "v2/Components/Layout/Layout";

import IMAGE_ATTRACTIVE_SALARY from "Assets/Images/image_16.jpg";
import IMAGE_FLEXIBLE_SCHEDULE from "Assets/Images/image_17.jpg";
import IMAGE_PROFESSIONAL_DEVELOPMENT from "Assets/Images/image_18.jpg";
import IMAGE_TUTOR_HERO from "Assets/Images/tutor-hero.jpg";
import Button from "v2/Components/Button/Button";

const Hero = () => {
  return (
    <section className="pt-[150px] pb-14 md:pb-0 px-5 md:px-[80px] bg-[#FFFAEB] min-h-[650px] flex justify-between">
      <div className="max-w-[628px]">
        <p className=" text-[10px] md:text-[13px] font-medium md:tracking-[5%] text-[#1B695C] mb-2.5 md:mb-1">
          SHARE YOUR EXPERTISE
        </p>

        <h1 className="text-[36px] md:text-[61px] leading-[45px] md:leading-[75px] font-medium">
          Empower, inspire and lead tech dreams
        </h1>

        <div className="md:hidden mt-5">
          <img
            src={IMAGE_TUTOR_HERO}
            className="min-w-[343px] object-cover rounded-[10px]"
            alt="sponsor-hero"
          />
        </div>

        <p className="text-[#2E2E2E] mt-5">
          Join iṣẹ EdTech to nurture and guide the next generation of tech
          minds. We offer you an opportunity to teach, advance your career, and
          earn
        </p>

        <div className="w-[208px] mt-10">
          <Button>
            <a href="#requirements">Explore requirements</a>
          </Button>
        </div>
      </div>

      <div className="hidden md:block">
        <img
          src={IMAGE_TUTOR_HERO}
          className="max-w-[678px] object-cover rounded-[10px]"
          alt="sponsor-hero"
        />
      </div>
    </section>
  );
};

const WhatYouNeed = () => {
  const Tick = () => {
    return (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        className="shrink-0 w-6 h-6 md:h-9 md:w-9"
      >
        <path
          d="M17.9999 34.6654C27.1666 34.6654 34.6666 27.1654 34.6666 17.9987C34.6666 8.83203 27.1666 1.33203 17.9999 1.33203C8.83325 1.33203 1.33325 8.83203 1.33325 17.9987C1.33325 27.1654 8.83325 34.6654 17.9999 34.6654Z"
          fill="#664EFE"
        />
        <path
          d="M10.9166 17.9987L15.6333 22.7154L25.0833 13.282"
          fill="#664EFE"
        />
        <path
          d="M10.9166 17.9987L15.6333 22.7154L25.0833 13.282M17.9999 34.6654C27.1666 34.6654 34.6666 27.1654 34.6666 17.9987C34.6666 8.83203 27.1666 1.33203 17.9999 1.33203C8.83325 1.33203 1.33325 8.83203 1.33325 17.9987C1.33325 27.1654 8.83325 34.6654 17.9999 34.6654Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const eligibilityCriterias = [
    "Relevant degree or certification in the subject area",
    "Proven expertise in the field of teaching",
    "Passionate about nurturing aspiring tech minds",
    "Strong communication and interpersonal skills",
    "Commitment to creating a supportive environment.",
    "Willingness to provide personalized guidance and feedback",
    "Strong organizational skills for effective planning",
  ];

  return (
    <section
      className="min-h-[584px] bg-[#F7F5FF] px-5 md:px-[80px] pt-20 pb-20 md:pb-0 flex flex-col md:flex-row"
      id="requirements"
    >
      <div className="md:w-7/12">
        <p className="text-[#1B695C] text-[13px] font-medium tracking-[5%] mb-3">
          WHAT YOU NEED
        </p>

        <h2 className="text-[#2E2E2E] text-[31px] md:text-[39px] font-medium mb-2">
          Eligibility criteria
        </h2>

        <p className="w-[372px] text-sm md:text-base leading-[25px]">
          Discover if you meet our requirements to become a valued tutor on iṣẹ́
          EdTech.
        </p>

        <div className="mt-24 hidden md:block">
          <svg width="466" height="133" viewBox="0 0 466 133" fill="none">
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 0 116.305)"
              fill="#664EFE"
            />
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 25.8984 79.3672)"
              fill="#FFD029"
            />
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 340 116.305)"
              fill="#664EFE"
            />
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 365.898 79.3672)"
              fill="#FFD029"
            />
          </svg>
        </div>
      </div>

      <div className="flex flex-col gap-y-6 mt-10 md:mt-0">
        {eligibilityCriterias.map((item) => (
          <div className="flex items-center gap-x-2.5">
            <Tick />

            <div className="text-[#737373] text-xs md:text-base">{item}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

const HowToBecomeATutor = () => {
  const steps = [
    {
      title: "Submit application",
      description: "Fill out and submit the tutor application form",
    },
    {
      title: "Interview process",
      description:
        "Once we receive your application, we'll reach out for an interview via email. Show us your passion for teaching and dedication to inspiring young minds",
    },
    {
      title: "Begin tutoring adventure",
      description:
        "Welcome to the team. Start teaching. Make a difference with your expertise and guidance",
    },
  ];
  return (
    <section className="px-5 md:px-[80px] flex flex-col md:flex-row pt-20 pb-24">
      <div className="md:w-1/2">
        <p className="text-[#1B695C] text-[10px] md:text-[13px] font-medium tracking-[5%]">
          IN 3 EASY STEPS
        </p>

        <h2 className="text-[31px] md:text-[49px] font-medium">
          How to become a tutor
        </h2>

        <div className="w-[208px] mt-8 hidden md:block">
          <Button type="tertiary">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScNC8u51CXzjTjxDV3F2tUPAW5m3nTk0UdnqHnG5TXaBtIWHA/viewform?usp=pp_url"
              target="_blank"
              rel="noreferrer"
            >
              Become a tutor
            </a>
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-y-10 relative mt-8 md:mt-0">
        <div className="h-[80%] w-[1.5px] bg-[#1B695C]  absolute left-[23px] -z-10"></div>
        {steps.map((step, i) => (
          <div key={i}>
            <div className="flex">
              <div className="h-12 w-12 rounded-full bg-[#1B695C] flex items-center justify-center text-white shrink-0 mr-10 border-[5px] border-white">
                {i + 1}
              </div>

              <div>
                <div className="md:text-xl text-[#2E2E2E] mb-2 font-medium">
                  {step.title}
                </div>
                <div className="md:w-[530px] text-[#737373] text-sm md:text-base">
                  {step.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="w-[208px] mt-8 md:hidden">
        <Button type="tertiary">Become a tutor</Button>
      </div>
    </section>
  );
};

const AreYouReady = () => {
  return (
    <section className="h-[416px] bg-[#FFFAEB] flex flex-col justify-center">
      <p className="text-[#2E2E2E] tracking-[5%] text-[10px] md:text-[13px] font-medium text-center">
        ARE YOU READY?
      </p>

      <h2 className="text-[#2E2E2E] text-[31px] md:text-[49px] text-center w-[280px] md:w-full font-medium mx-auto">
        Begin your teaching journey with iṣẹ́
      </h2>

      <div className="w-[158px] mt-8 mx-auto">
        <Button>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScNC8u51CXzjTjxDV3F2tUPAW5m3nTk0UdnqHnG5TXaBtIWHA/viewform?usp=pp_url"
            target="_blank"
            rel="noreferrer"
          >
            Apply now
          </a>
        </Button>
      </div>
    </section>
  );
};

const WhyTeachAtIse = () => {
  return (
    <section className="px-5 md:px-[80px] pt-10 pb-20">
      <p className="text-[10px] md:text-[13px] text-[#664EFE] font-medium md:text-center">
        WHAT YOU WILL GET
      </p>

      <h2 className="text-[31px] md:text-[49px] font-medium text-[#2E2E2E] md:text-center mb-9">
        Why teach at iṣẹ́?
      </h2>

      <div className="flex flex-col md:flex-row gap-x-16 gap-y-10">
        <div className="md:w-1/3">
          <img
            src={IMAGE_ATTRACTIVE_SALARY}
            alt="attractive salary"
            className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-2.5 md:mb-4"
          />

          <h3 className="text-[#2E2E2E] text-lg md:text-[25px] font-medium mb-2.5">
            Attractive salary package
          </h3>

          <p className="text-[#2E2E2E] text-sm md:text-base">
            We offer attractive benefits and rewards for your valuable impact on
            the lives of our students
          </p>
        </div>

        <div className="md:w-1/3">
          <img
            src={IMAGE_FLEXIBLE_SCHEDULE}
            alt="flexible schedule"
            className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-2.5 md:mb-4"
          />

          <h3 className="text-[#2E2E2E] text-lg md:text-[25px] font-medium mb-2.5">
            Flexible schedule
          </h3>

          <p className="text-[#2E2E2E] text-sm md:text-base">
            Experience the freedom to make a difference on your terms. Set your
            schedule and balance your tutoring commitments with your personal
            life
          </p>
        </div>

        <div className="md:w-1/3">
          <img
            src={IMAGE_PROFESSIONAL_DEVELOPMENT}
            alt="professional salary"
            className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-2.5 md:mb-4"
          />

          <h3 className="text-[#2E2E2E] text-lg md:text-[25px] font-medium mb-2.5">
            Professional development
          </h3>

          <p className="text-[#2E2E2E] text-sm md:text-base">
            Access resources and a supportive community to enhance your skills
            and expand your expertise
          </p>
        </div>
      </div>
    </section>
  );
};

const BecomeATutor = () => {
  return (
    <Layout>
      <Hero />

      <WhatYouNeed />

      <HowToBecomeATutor />

      <WhyTeachAtIse />

      <AreYouReady />
    </Layout>
  );
};

export default BecomeATutor;
