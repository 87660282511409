import { Routes, Route } from "react-router-dom";
import { routeComponents } from "./v2/Utilities/routeComponents";
import useScrollToTop from "Utilities/Hooks/useScrollToTop";

function App() {
  useScrollToTop();

  return (
    <Routes>
      {routeComponents.map((component, i) => {
        return <Route path={component.route} element={component.component} />;
      })}
    </Routes>
  );
}

export default App;
