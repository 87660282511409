import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import Layout from "../../Components/Layout/Layout";
import SectionsNav from "../../Components/SectionsNav/SectionsNav";
import { AppContext } from "../../Context/AppContext";

import { navItemsType } from "../../Utilities/types";
import CourseDetailWhenWeStart from "../CourseDetailWhenWeStart/CourseDetailWhenWeStart";
import CoursePageCurricullum from "../CoursePageCurricullum/CoursePageCurricullum";
import CoursePageEarnACertificate from "../CoursePageEarnACertificate/CoursePageEarnACertificate";
import CoursePageHeroSection from "../CoursePageHeroSection/CoursePageHeroSection";
import CoursePageOtherCourses from "../CoursePageOtherCourses/CoursePageOtherCourses";
import CoursePageTutorsWork from "../CoursePageTutorsWork/CoursePageTutorsWork";
import CoursePageTwoWays from "../CoursePageTwoWays/CoursePageTwoWays";
import CoursePageWhatIs from "../CoursePageWhatIs/CoursePageWhatIs";
import CoursePageWhatYouNeed from "../CoursePageWhatYouNeed/CoursePageWhatYouNeed";
import CoursePageWhoShouldEnrol from "../CoursePageWhoShouldEnrol/CoursePageWhoShouldEnrol";
import HomePageAffordable from "../HomePageAffordable/HomePageAffordable";
import HomePageBuildWithEveryone from "../HomePageBuildWithEveryone/HomePageBuildWithEveryone";
import HomePageFaqs from "../HomePageFaqs/HomePageFaqs";
import SchoolPageCoursesSection from "../SchoolPageCoursesSection/SchoolPageCoursesSection";
import classes from "./CoursePage.module.css";
// import Svgs from "../../../Assets/Svgs";
import IMAGE_LEARN_BLOCK from "../../../Assets/Svgs/learn-block.svg";
import IMAGE_WORK_BLOCK from "../../../Assets/Svgs/work-block.svg";
import IMAGE_SUCCEED_BLOCK from "../../../Assets/Svgs/succeed-block.svg";
import work1 from "../../Assets/Images/work1.png";
import work2 from "../../Assets/Images/work2.png";
import learn1 from "../../Assets/Images/learn1.png";
import learn2 from "../../Assets/Images/learn2.png";
import succeed1 from "../../Assets/Images/suceed1.png";
import succeed2 from "../../Assets/Images/suceed2.png";
import {
  endpoints,
  getCourseBySlug,
  getSchoolBySlug,
  getSchoolCoursesBySlug,
} from "Services";

const CoursePage = () => {
  // Context
  const {
    courseOverview,
    courseCurricullum,
    courseTutors,
    coursePricing,
    courseRequirement,
    courseAdmission,
    courseFaqs,
  } = useContext(AppContext);

  //   Router
  const { courseSlug, schoolSlug } = useParams();

  const { data: response, isLoading } = useSWR(
    endpoints.GET_COURSE_BY_SLUG(courseSlug || ""),
    getCourseBySlug
  );

  const { data: schoolResponse, isLoading: loadingSchool } = useSWR(
    endpoints.GET_SCHOOL_BY_SLUG(schoolSlug || ""),
    getSchoolBySlug
  );

  const { data: schoolCoursesResponse, isLoading: loadingSchoolCourses } =
    useSWR(
      endpoints.GET_SCHOOL_COURSES_BY_SLUG(schoolSlug || ""),
      getSchoolCoursesBySlug
    );

  const course = response?.data;

  const schoolCourses = schoolCoursesResponse?.data || [];

  const otherCourses = schoolCourses.filter(
    (course) => course.courses_slug !== courseSlug
  );

  const school = schoolResponse?.data;

  // States
  const [navItems, setNavItems] = useState<navItemsType[]>([
    {
      title: "Overview",
      isActive: true,
      activeComponent: null,
      onClick: () => {
        courseOverview.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Curriculum",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseCurricullum.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Tutors",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseTutors.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Pricing",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        coursePricing.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Requirement",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseRequirement.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Admission",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseAdmission.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },

    {
      title: "FAQs",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseFaqs.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
  ]);

  const mission = [
    {
      title: "Learn",
      images: [learn1, learn2],
      icon: IMAGE_LEARN_BLOCK,
    },
    {
      title: "Work",
      images: [work1, work2],
      icon: IMAGE_WORK_BLOCK,
    },
    {
      title: "Succeed",
      images: [succeed1, succeed2],
      icon: IMAGE_SUCCEED_BLOCK,
    },
  ];

  return (
    <Layout>
      <CoursePageHeroSection course={course} isLoading={isLoading} />

      <div className={classes.sectionNav}>
        <SectionsNav navItems={navItems} setNavItems={setNavItems} />
      </div>

      <CoursePageWhatIs course={course} isLoading={isLoading} />

      <CoursePageCurricullum />

      <CoursePageWhoShouldEnrol />
      {/* TODO: Have conversations on the curricullum, how to send to email and the curricullum endpoint */}
      {/* TODO: Have discussions about the course ttuors endpoint */}

      <CoursePageTutorsWork />

      <CoursePageTwoWays />

      <CoursePageEarnACertificate />

      <CoursePageWhatYouNeed />

      <div ref={courseFaqs}>
        <HomePageFaqs />
      </div>

      {otherCourses?.length > 0 && (
        <CoursePageOtherCourses
          isLoading={loadingSchoolCourses || loadingSchool}
          courses={otherCourses}
          activeSchool={school}
        />
      )}

      {course?.cohorts.length ? (
        <CourseDetailWhenWeStart
          routeToMainApp={() => {}}
          startDate={course.cohorts[0].start_date}
          endDate={course.cohorts[0].end_date}
          deadline={course.cohorts[0].application_deadline}
        />
      ) : null}
      <HomePageAffordable />
    </Layout>
  );
};

export default CoursePage;
