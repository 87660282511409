import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as Eclipse658 } from "./eclipse-658.svg";
import { ReactComponent as UnsureArrow } from "./unsure-arrow.svg";
import { ReactComponent as Active } from "./active.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as CourseHero } from "./course-hero.svg";
import { ReactComponent as CheckMark } from "./checkmark.svg";
import { ReactComponent as LearnBlock } from "./learn-block.svg";
import { ReactComponent as WorkBlock } from "./work-block.svg";
import { ReactComponent as SucceedBlock } from "./succeed-block.svg";
import { ReactComponent as TheTeam } from "./the-team.svg";

const Svgs = {
  Star,
  Eclipse658,
  UnsureArrow,
  Active,
  ArrowRight,
  CourseHero,
  CheckMark,
  LearnBlock,
  WorkBlock,
  SucceedBlock,
  TheTeam,
};

export default Svgs;
