import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import QuizLayout from "../../Components/QuizLayout/QuizLayout";
import fitQuiz1 from "../../Assets/Images/fitQuiz1.svg";
import fitQuiz2 from "../../Assets/Images/fitQuiz2.svg";
import fitQuiz3 from "../../Assets/Images/fitQuiz3.svg";
import fitQuiz4 from "../../Assets/Images/fitQuiz4.svg";
import fitQuiz5 from "../../Assets/Images/fitQuiz5.svg";
import fitQuiz6 from "../../Assets/Images/fitQuiz6.svg";
import fitQuiz7 from "../../Assets/Images/fitQuiz7.svg";
import fitQuiz8 from "../../Assets/Images/fitQuiz8.svg";
import fitQuiz9 from "../../Assets/Images/fitQuiz9.svg";
import fitQuiz10 from "../../Assets/Images/fitQuiz10.svg";
import fitQuiz11 from "../../Assets/Images/fitQuiz11.svg";
import fitQuiz12 from "../../Assets/Images/fitQuiz12.svg";
import fitQuiz13 from "../../Assets/Images/fitQuiz13.svg";
import fitQuiz14 from "../../Assets/Images/fitQuiz14.svg";
import fitQuiz15 from "../../Assets/Images/fitQuiz15.svg";
import fitQuiz16 from "../../Assets/Images/fitQuiz16.svg";
import fitQuiz17 from "../../Assets/Images/fitQuiz17.svg";
import fitQuiz18 from "../../Assets/Images/fitQuiz18.svg";
import fitQuiz19 from "../../Assets/Images/fitQuiz19.svg";
import FitQuizIntroduction from "../FitQuizIntroduction/FitQuizIntroduction";
import { pagePropsType, quizDataTypes } from "../../Utilities/types";
import FitQuixCompleted from "../FitQuixCompleted/FitQuixCompleted";

const FitQuiz = () => {
  // Router
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");

  //   States
  const [pageProps, setPageProps] = useState<pagePropsType>({
    header: "",
    description: "",
    image: "",
    caption: "",
    body: <></>,
    id: "",
  });
  const [quizData, setQuizData] = useState<quizDataTypes>({
    name: "",
  });

  //   Effects
  useEffect(() => {
    if (step === "intro") {
      setPageProps({
        header: "Let’s help you find a tech path you’ll enjoy",
        description:
          "This quiz will take about 5-10 minutes. Your responses will help us recommend the best courses and career paths for you.",
        caption: "HI THERE 😊",
        image: fitQuiz1,
        body: <FitQuizIntroduction />,
        id: "intro",
      });
    } else if (step === "1") {
      setPageProps({
        header: "Before we get started, tell us your name? *",
        description: "First name alone is fine",
        caption: null,
        image: fitQuiz2,
        body: "answer",
        id: "name",
      });
    } else if (step === "2") {
      setPageProps({
        header: `Hi ${
          quizData?.name || "scholar"
        }, Which best describes your current situation? *`,
        description: null,
        caption: null,
        image: fitQuiz3,
        body: "multiple-choice",
        id: "situation",
        option: [
          "I’m exploring career options in tech for the first time",
          "I’m currently in a non-tech role and want to transition into tech",
          "I’m in a tech job but want to learn new skills or switch roles.",
          "None of these apply to me",
        ],
      });
    } else if (step === "3") {
      setPageProps({
        header: `What kind of roles do you prefer? *`,
        description: null,
        caption: null,
        image: fitQuiz4,
        body: "multiple-choice",
        id: "preference",
        option: [
          "Working in a highly technical role",
          "Working in semi-technical role",
          "Working in non-technical role ",
          " I’m not sure. I can fit into any role",
        ],
      });
    } else if (step === "4") {
      setPageProps({
        header: `Do you prefer to: *`,
        description: null,
        caption: null,
        image: fitQuiz5,
        body: "multiple-choice",
        id: "preference",
        option: [
          "Create new solutions and innovations",
          "Improve and solve existing problems",
          "I can do both. It depends on the situation ",
        ],
      });
    } else if (step === "5") {
      setPageProps({
        header: `How do you prefer to work? *`,
        description: null,
        caption: null,
        image: fitQuiz6,
        body: "multiple-choice",
        id: "workPreference",
        option: [
          "I prefer to work solo/ independently",
          "I like working as part of a team",
          "I enjoy both  depending on the task",
        ],
      });
    } else if (step === "6") {
      setPageProps({
        header: `What types of activities do you enjoy in your free time? *`,
        description: null,
        caption: null,
        image: fitQuiz7,
        body: "long-answer",
        id: "activities",
      });
    } else if (step === "7") {
      setPageProps({
        header: `How interested are you in exploring how technology can impact businesses? *`,
        description: null,
        caption: null,
        image: fitQuiz8,
        body: "multiple-choice",
        id: "interest",
        option: [
          "Very interested",
          "Somewhat interested",
          "Neutral",
          "Not interested",
        ],
      });
    } else if (step === "8") {
      setPageProps({
        header: `How would you describe your ability to pay attention to detail? *`,
        description: null,
        caption: null,
        image: fitQuiz9,
        body: "multiple-choice",
        id: "attention",
        option: [
          "I often miss small details",
          "I sometimes overlook tiny details but usually catch the important ones",
          "I am generally good at noticing details",
          "I am very meticulous and rarely miss details",
        ],
      });
    } else if (step === "9") {
      setPageProps({
        header: `How much do you enjoy communicating with others? *`,
        description: null,
        caption: null,
        image: fitQuiz10,
        body: "multiple-choice",
        id: "communication",
        option: [
          "I prefer minimal interaction and communication with others",
          "I don't mind communicating, but it's not my preference",
          "I enjoy communicating with others in moderation",
          "I thrive on communicating and interacting with people",
        ],
      });
    } else if (step === "10") {
      setPageProps({
        header: `How confident are you in your problem-solving skills? *`,
        description: null,
        caption: null,
        image: fitQuiz11,
        body: "multiple-choice",
        id: "confidence",
        option: [
          "I often struggle to find solutions to problems",
          "I can solve problems with some effort",
          "I usually find effective solutions to problems",
          "I am very confident in my ability to solve complex problems",
        ],
      });
    } else if (step === "11") {
      setPageProps({
        header: `How confident are you in your problem-solving skills? *`,
        description: null,
        caption: null,
        image: fitQuiz12,
        body: "multiple-choice",
        id: "comfort",
        option: [
          "I find numbers and calculations challenging",
          "I can manage basic calculations but prefer to avoid complex ones",
          "I am comfortable with numbers and can handle most calculations",
          "I excel at working with numbers and complex calculations",
        ],
      });
    } else if (step === "12") {
      setPageProps({
        header: `Choose up to 3 soft skills you have: *`,
        description: null,
        caption: null,
        image: fitQuiz13,
        body: "multiple-multiple-choice",
        id: "softSkills",
        option: [
          "Organization",
          "Leadership",
          "Creativity",
          "Teamwork",
          "Logical thinking",
          "Persuasion",
        ],
      });
    } else if (step === "13") {
      setPageProps({
        header: `What technical skills do you currently possess and/or want to improve on? *`,
        description: null,
        caption: null,
        image: fitQuiz14,
        body: "long-answer",
        id: "techSkills",
      });
    } else if (step === "14") {
      setPageProps({
        header: `Which do you prefer: *`,
        description: null,
        caption: null,
        image: fitQuiz15,
        body: "multiple-choice",
        id: "workConvenience",
        option: [
          "Working behind the scenes",
          "Engaging with customers",
          "Both",
          "I don’t prefer any",
        ],
      });
    } else if (step === "15") {
      setPageProps({
        header: `What kind of impact do you want to make in your career? *`,
        description: null,
        caption: null,
        image: fitQuiz16,
        body: "long-answer",
        id: "careerImpact",
      });
    } else if (step === "16") {
      setPageProps({
        header: `We’d like to send your results to your email *`,
        description: null,
        caption: null,
        image: fitQuiz17,
        body: "answer",
        id: "email",
        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      });
    } else if (step === "17") {
      setPageProps({
        header: `Is there anything else you’d like us to know about you?`,
        description: null,
        caption: null,
        image: fitQuiz18,
        body: "long-answer",
        id: "anythingElse",
      });
    } else if (step === "complete") {
      setPageProps({
        header: "Thank you for completing the quiz.",
        description:
          "Our course advisor will review your responses and get back to you within 24 hours with personalized course recommendations.",
        caption: "GREAT JOB!  👏🏼👏🏼",
        image: fitQuiz19,
        body: <FitQuixCompleted />,
        id: "intro",
      });
    }

    // eslint-disable-next-line
  }, [step]);

  return (
    <QuizLayout {...pageProps} quizData={quizData} setQuizData={setQuizData} />
  );
};

export default FitQuiz;
