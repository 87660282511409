import Button from "../../Components/Button/Button";
import classes from "./HomePageAffordable.module.css";
import work1 from "../../Assets/Images/work1.png";
import work2 from "../../Assets/Images/work2.png";
import learn1 from "../../Assets/Images/learn1.png";
import learn2 from "../../Assets/Images/learn2.png";
import succeed1 from "../../Assets/Images/suceed1.png";
import succeed2 from "../../Assets/Images/suceed2.png";
import learnIcon from "../../Assets/Images/learnIcon.png";
import succeedIcon from "../../Assets/Images/succeedIcon.png";
import workIcon from "../../Assets/Images/workIcon.png";
import { routeToMainApp } from "../../HelperFunctions/routeToMainApp";
import Svgs from "../../../Assets/Svgs";

const defaultMission = [
  {
    title: "Learn",
    images: [learn1, learn2],
    icon: learnIcon,
  },
  {
    title: "Work",
    images: [work1, work2],
    icon: workIcon,
  },
  {
    title: "Succeed",
    images: [succeed1, succeed2],
    icon: succeedIcon,
  },
];

const HomePageAffordable = (props: {
  mission?: { title: string; images: string[]; icon: string }[];
}) => {
  const { mission = defaultMission } = props;
  const missionRepeated = [...mission, ...mission];

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <p>WE ARE AFFORDABLE</p>
        <h2>
          Skill up on any course with an initial sum of{" "}
          <span className="relative">
            ₦15,000
            <Svgs.Eclipse658 className="absolute top-0 left-0 text-[#664EFE] w-[140.9px] h-[38.51px] md:w-[200px] md:h-[48px]" />
          </span>
        </h2>
        <p>
          If you've made it this far, you must be at least a little curious.
          Sign up and take the first step toward your goals.
        </p>
        <Button onClick={routeToMainApp}> Start learning</Button>
      </div>

      <div className={classes.missionContainer}>
        {missionRepeated.map((data) => {
          return (
            <div key={data.title} className={`${classes.mission}`}>
              <div>
                <img src={data.icon} alt={data.title} />
                <span>{data?.title}</span>
              </div>

              <div className={classes.imageSection}>
                {data.images?.map((image, j) => {
                  return <img src={image} alt={data?.title} key={j} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HomePageAffordable;
