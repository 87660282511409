export const routes = {
  HOME: "/",
  BECOME_A_TUTOR_FORM: "/become-a-tutor/tutor-application-form",
  SPONSOR_A_STUDENT_FORM: "/sponsor-a-student/sponsorship-application-form",
  PARTNER_WITH_US: "/partner-with-us",
  FIT_QUIZ: "/fit-quiz",
  SPONSOR_A_STUDENT: "/sponsor-a-student",
  BECOME_A_TUTOR: "/become-a-tutor",
  SCHOOL: "/:schoolSlug",
  COURSE: "/:schoolSlug/:courseSlug",
  WHY_ISE_EDTECH: "/why-ise-school",
  DONATE_A_LAPTOP: "/sponsor-a-student/laptop-donation-form",
  ERROR: "*",
  FAQS: "/faqs",
  PROGRAM_ADVISOR: "/program-advisor",
};

export const queries = {
  FIT_QUIZ_INTRO: "intro",
};
