import { useContext } from "react";
import Button from "../../Components/Button/Button";
import { AppContext } from "../../Context/AppContext";
import classes from "./CoursePageWhatIs.module.css";
import Svgs from "../../../Assets/Svgs";
import { TCourse } from "Services";
import Skeleton from "react-loading-skeleton";

interface IProps {
  course?: TCourse;
  isLoading: boolean;
}

type TCourseObjective = {
  id: number;
  value: string;
};

const CoursePageWhatIs = ({ course, isLoading }: IProps) => {
  const { courseOverview } = useContext(AppContext);

  const getCourseObjectives = (data: string): TCourseObjective[] | [] => {
    if (!data) return [];

    let parsedData = JSON.parse(data);

    if (typeof parsedData === "string") {
      parsedData = JSON.parse(parsedData);
    }

    return parsedData;
  };

  const courseObjectives = getCourseObjectives(course?.course_objective || "");

  return (
    <section className={classes.container} ref={courseOverview}>
      <div className={classes.textSection}>
        <h4>WHAT IS</h4>
        <h2>{isLoading ? <Skeleton /> : course?.name}</h2>
        <p>{isLoading ? <Skeleton /> : course?.description}</p>
      </div>

      <div className="bg-[#FFFAEB] border-2 border-[#664EFE] max-w-[644px] rounded-[10px] p-5 md:py-8">
        <h5 className="text-[20px] font-medium mb-4">Your learning outcomes</h5>

        <ul className="flex flex-col gap-y-3">
          {courseObjectives?.map((item: any) => (
            <li className="flex items-center gap-x-4">
              <Svgs.CheckMark className="shrink-0 w-6 h-6 md:w-8 md:h-8" />

              <span className="leading-[25px] text-[#2E2E2E] text-sm md:text-base">
                {item.value}
              </span>
            </li>
          ))}
        </ul>

        <div className="flex w-[142px] mt-6">
          <Button>Enrol now</Button>
        </div>

        <small className="text-[13px] text-[#737373] mt-2.5 block">
          100% money back guarantee if you change your mind within 48 hours. See
          refund policy
        </small>
      </div>
    </section>
  );
};

export default CoursePageWhatIs;
