import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isPast,
} from "date-fns";

/**
 * Calculates the time remaining until a given deadline.
 *
 * @param {string} deadline - The deadline date as an ISO 8601 string.
 * @returns {Object} An object containing the days, hours, minutes, and seconds remaining, along with a message.
 * @property {number} days - The number of full days remaining.
 * @property {number} hours - The number of hours remaining (less than a full day).
 * @property {number} minutes - The number of minutes remaining (less than an hour).
 * @property {number} seconds - The number of seconds remaining (less than a minute).
 * @property {string} message - A formatted message indicating the time remaining or that the deadline has passed.
 */
const getTimeUntilDeadline = (deadline: string | null) => {
  if (!deadline) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      message: "No deadline passed.",
    };
  }
  // Parse the deadline date
  const deadlineDate = new Date(deadline);
  const currentDate = new Date();

  if (isPast(deadlineDate)) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      message: "The deadline has passed.",
    };
  }

  // Calculate days, hours, minutes, and seconds using date-fns
  const days = differenceInDays(deadlineDate, currentDate);
  const hours = differenceInHours(deadlineDate, currentDate) % 24;
  const minutes = differenceInMinutes(deadlineDate, currentDate) % 60;
  const seconds = differenceInSeconds(deadlineDate, currentDate) % 60;

  return {
    days,
    hours,
    minutes,
    seconds,
    message: `Time left: ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds.`,
  };
};

export default getTimeUntilDeadline;
