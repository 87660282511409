import { useState } from "react";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "./AboutUsFaq.module.css";
import { activeToggler } from "v2/HelperFunctions/activeTogglers";

const AboutUsFaq = () => {
  const faqs = [
    {
      question: "What is iṣẹ́ School?",
      answer:
        "iṣẹ́ School is a virtual learning platform that provides young Africans with technical and operational learning courses including backend development, data analysis, customer success management and more. We focus on building both technical skills and essential non-technical abilities like project management, communication, and critical thinking that are crucial for thriving in remote work environments.",
    },
    {
      question: "How does iṣẹ́ School support learners?",
      answer:
        "Our user-friendly platform is designed to keep you motivated throughout your learning journey. We provide continuous personalised tutor support, peer interaction and community support to keep you engaged as you learn.",
    },
    {
      question: "Who can benefit from iṣẹ́ School's courses?",
      answer:
        "Aspiring techies, career changers or tech enthusiasts who want to kickstart their tech careers with beginner-friendly courses can benefit fully from what we offer.",
    },
    {
      question: "How can I get involved with iṣẹ́ School's community?",
      answer:
        "Once you register for a course in any of the schools, you gain access to the student community and participate in community events or initiatives.",
    },
    {
      question:
        "Is iṣẹ́ School's content accessible to an international audience?",
      answer:
        "While our primary goal is to help Africans, our courses are designed to appeal to everyone who wants to gain technical and operational training.",
    },
  ];

  const [faqState, setFaqState] = useState(
    faqs.map((data) => {
      return { ...data, isActive: false };
    })
  );

  return (
    <>
      {faqState.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown
              question={data?.question}
              answer={data?.answer}
              onClick={() => {
                activeToggler(i, faqState, setFaqState);
              }}
              isActive={data?.isActive}
            />
          </div>
        );
      })}
    </>
  );
};

export default AboutUsFaq;
