import { TCourse, TSchool, TSchoolCourse } from "Services";
import CourseCard from "../../Components/CourseCard/CourseCard";
import Loader from "../../Components/Loader/Loader";
import { courseType, schoolsType } from "../../Utilities/types";
import classes from "./CoursePageOtherCourses.module.css";

type CoursePageOtherCoursesTypes = {
  courses: TSchoolCourse[];
  isLoading: boolean;
  activeSchool?: TSchool;
};

const CoursePageOtherCourses = ({
  courses,
  isLoading,
  activeSchool,
}: CoursePageOtherCoursesTypes) => {
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <p>YOU MAY BE INTERESTED</p>
        <h2>Other courses in {activeSchool?.name}</h2>
      </div>

      <div className={classes.schoolsSection}>
        {isLoading ? (
          <Loader />
        ) : courses?.length < 1 ? (
          <p className={classes.noCourses}>
            No courses available in this school
          </p>
        ) : (
          courses?.map((course) => {
            return <CourseCard data={course} />;
          })
        )}
      </div>
    </section>
  );
};

export default CoursePageOtherCourses;
